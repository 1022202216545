// animating Header
#rightbottom, #lefttop, #righttop, #left, #right, #Moving, #toprightconnection, #cloud {
    animation: levitate 1s ease-in-out infinite alternate;
  }
  
  #lefttopshadow, #righttopshadow, #shadowleft, #shadowright {
    animation: shadows 1s ease-in-out infinite alternate;
    transform-origin: center;
    transform-box: fill-box;
  }
  
  #dots, #dots_3 {
    animation: dots 1s infinite alternate;
  }
  
  #rightdiods {
      :nth-child(0) {
        animation: setfastlight 1.5s steps(1) infinite alternate;
      }
      :nth-child(1) {
        animation: setfastlight 1.5s steps(1) 0.25s infinite alternate;
      }
      :nth-child(2) {
        animation: setfastlight 1.5s steps(1) 0.5s infinite alternate;
      }
      :nth-child(3) {
        animation: setfastlight 1.5s steps(1) 0.75s infinite alternate;
      }
      :nth-child(4) {
        animation: setfastlight 1.5s steps(1) 1s infinite alternate;
      }
      :nth-child(5) {
        animation: setfastlight 1.5s steps(1) 1.25s infinite alternate;
      }
      :nth-child(6) {
        animation: setfastlight 1.5s steps(1) 1.5s infinite alternate;
      }
  }
  
  #centerdiods {
    :nth-child(1) {
      animation: setslowlight 5s steps(1) infinite alternate;
    }
    :nth-child(2) {
      animation: setslowlight 5s steps(1) 1s infinite alternate;
    }
    :nth-child(3) {
      animation: setslowlight 5s steps(1) 2s infinite alternate;
    }
    :nth-child(4) {
      animation: setslowlight 5s steps(1) 3s infinite alternate;
    }
    :nth-child(5) {
      animation: setslowlight 5s steps(1) 4s infinite alternate;
    }
  }
  
  @keyframes setfastlight {
    0% {
      fill: #D4ECFF;
    }
    20% {
      fill: #FF2C9C;
    }
    40% {
      fill: #D4ECFF;
    }
  }
  
  @keyframes setslowlight {
    0% {
      fill: #FF2C9C;
    }
    50% {
      fill: #D4ECFF;
    }
  }
  
  @keyframes levitate {
    from {
      transform: translateY(0%);
    }
    to {
      transform: translateY(-2%);
    }
  }
  
  @keyframes shadows {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.8);
    }
  }
  
  @keyframes dots {
    from {
      transform: translate(20px, 10px);
    }
    to {
      transform: translate(0);
    }
  }
  
  //animating ListImg
  #cloudarrow {
    animation: neon 1s ease-in-out infinite alternate;
  
    > path {
      animation: neon 1s ease-in-out infinite alternate;
    }
  }
  
  #hydraulic {
    :nth-child(1) {
      animation: hydraulic 1s ease-in-out infinite alternate;
    }
    :nth-child(2) {
      animation: hydraulic 1s ease-in-out 1s infinite alternate;
    }
    :nth-child(3) {
      animation: hydraulic 1s ease-in-out infinite alternate;
    }
  }
  
  #top1, #top2, #top3, #top4 {
    animation: goDown 2s ease-in-out infinite alternate;
  }
  
  #bottom1, #bottom2, #bottom3, #bottom4, #Vector_199, #diods {
    animation: goUp 2s ease-in-out infinite alternate;
  }
  
  #centraldiods {
    :nth-child(0) {
      animation: fastDiods 1.75s steps(1) infinite alternate;
    }
    :nth-child(1) {
      animation: fastDiods 1.75s steps(1) 0.25s infinite alternate;
    }
    :nth-child(2) {
      animation: fastDiods 1.75s steps(1) 0.5s infinite alternate;
    }
    :nth-child(3) {
      animation: fastDiods 1.75s steps(1) 0.75s infinite alternate;
    }
    :nth-child(4) {
      animation: fastDiods 1.75s steps(1) 1s infinite alternate;
    }
    :nth-child(5) {
      animation: fastDiods 1.75s steps(1) 1.25s infinite alternate;
    }
    :nth-child(6) {
      animation: fastDiods 1.75s steps(1) 1.5s infinite alternate;
    }
    :nth-child(7) {
      animation: fastDiods 1.75s steps(1) 1.75s infinite alternate;
    }
  }
  
  @keyframes fastDiods {
    0% {
      fill: #D4ECFF;
    }
    20% {
      fill: #173d7a;
    }
    40% {
      fill: #D4ECFF;
    }
  }
  
  @keyframes neon {
    from {
      fill: #D4ECFF;
    }
    to {
      fill: #9dd2fc;
    }
  }
  
  @keyframes hydraulic {
    from {
      transform: translateY(0%);
    }
    to {
      transform: translateY(-2%);
    }
  }
  
  @keyframes goDown {
    from {
      transform: translateY(0%);
    }
    to {
      transform: translateY(1%);
    }
  }
  
  @keyframes goUp {
    from {
      transform: translateY(0%);
    }
    to {
      transform: translateY(-1%);
    }
  }