@import-normalize;

@font-face {
  font-family: 'Calibre';
  src: url("../public/fonts/CalibreThin.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url("../public/fonts/CalibreRegular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url("../public/fonts/CalibreMedium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url("../public/fonts/CalibreSemibold.otf") format("opentype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

body {
  margin: 0;
  color: #5F5F5F;
  padding: 0px;
}

body, * {
  font-family: 'Calibre', sans-serif;
}

html {
  font-size: 20px;
}